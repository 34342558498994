import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';
import HeroSubscribeForm from 'components/Form/HeroSubscribeForm';
import ContentfulDynamicHero from 'components/Contentful/ContentfulDynamicHero';
import BlogCardGroupSection from 'components/Sections/ImageCardSections/BlogCardGroupSection';
import DemoFormSection from 'components/Sections/DemoFormSection';
import ToolboxLayout from 'layouts/toolboxLayout';
import useAllBlogPosts from 'hooks/useAllBlogPosts';

const BlogTemplate = (props) => {
  const allPosts = useAllBlogPosts();
  const hubType = 'blog';
  const seo = props.data.contentfulSeo;
  const dynamicHero = props.data.contentfulDynamicHero;
  const { page } = props.pageContext;

  if (page) {
    delete seo.canonicalUrl;
    if (page >= 5) {
      seo.robotOptions = { follow: 'FOLLOW' };
      seo.indexibility.indexibility = 'Non-Indexable';
    }
  }
  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Blog',
        icon: null,
        url: null,
        hideInMobile: true,
      },
    ],
    absolute: true,
  };

  return (
    <ToolboxLayout
      breadcrumbs={breadcrumbs}
      contentfulSeo={page ? { ...seo, pageTitle: `${seo.pageTitle} page ${page}` } : seo}
      layout="toolbox"
      {...props}
    >
      <BlogContent>
        <SectionLayout className="blog-content--layout" backgroundColor="white">
          <BlogDynamicHero
            {...dynamicHero}
            images={[]}
            customInlineForm={<HeroSubscribeForm buttonType="secondary" />}
          />
          {page && <BlogPageH1>{`${seo.pageTitle} page ${page}`}</BlogPageH1>}
          <BlogCardGroupSection
            allContent={allPosts}
            hubType={hubType}
            industries={props.pageContext.industries}
            topics={props.pageContext.topics}
            tagsCookie="blog_tags"
            page={page}
            location={props.location}
          />
        </SectionLayout>
        <DemoFormSection
          backgroundColor="var(--dark)"
          formTitle="Let’s get started."
          description="Explore why 100,000+ contractors trust Service Titan to run their business."
          title="Book a personalized demo today."
        />
      </BlogContent>
    </ToolboxLayout>
  );
};

export default BlogTemplate;

export const pageQuery = graphql`
  query BlogTemplateQuery {
    contentfulDynamicHero(internalName: { eq: "Dynamic Hero - Blog" }) {
      ...DynamicHero
    }
    contentfulSeo(internalName: { eq: "Blog" }) {
      ...Seo
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const BlogPageH1 = styled.h1`
  display: none;
`;

const BlogDynamicHero = styled(ContentfulDynamicHero)`
  padding-top: 70px;

  .hero-copy {
    .hero-copy-description {
      font-size: var(--font-size-25);
      margin: 50px auto 0px;
      padding: 0px 28px;
      color: var(--dark);
      line-height: 36px;
    }
  }

  .hero-subscribe-form-container {
    margin-top: 40px;
    width: 100%;
    .data-layer-form {
      justify-content: center;
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    .section-layout--inner-container {
      padding: 60px 0px 28px;
    }
    .hero-copy {
      margin-bottom: 0;
      .hero-copy-description {
        margin-top: 20px;
        font-size: var(--font-size-20);
        padding: 0px;
      }
    }
  }
`;

const BlogContent = styled.div`
  padding-top: 60px;
  .blog-content--layout .section-layout--inner-container {
    padding-top: 0;
  }

  .section-blog-details-legal-info {
    opacity: 1;
    width: 500px;
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-150);
    text-align: center;
    margin: 0 auto -70px auto;
    color: var(--darkest);
  }

  .section-blog-details-submit-legal-info {
    opacity: 1;
    width: 500px;
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-150);
    text-align: center;
    margin: 0 auto -70px auto;
    color: var(--green);
  }

  @media (max-width: 767px) {
    .section-blog-details-legal-info {
      width: 100%;
      margin: 0 auto -89px auto;
    }
  }
`;
