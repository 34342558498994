import { graphql, useStaticQuery } from 'gatsby';

const getPosts = (posts) => posts.edges.map((edge) => edge.node);

const useAllBlogPosts = () => {
  const { allContentfulBlog } = useStaticQuery(graphql`
    query {
      allContentfulBlog(sort: { fields: [publishDate], order: DESC }) {
        edges {
          node {
            ...BlogSearchCard
          }
        }
      }
    }
  `);

  return getPosts(allContentfulBlog);
};

export default useAllBlogPosts;
