/* eslint-disable no-use-before-define */
import React from 'react';
import Bookmark from 'components/Page/Toolbox/Bookmark';
import Cards from 'components/Core/Cards';
import { taxonomyString } from 'utils/text';
import ContentSearchSection from 'components/Sections/ContentSearchSection';

const BlogCardGroupSection = ({
  allContent = [],
  hubType,
  industries = [],
  topics = [],
  pageSize = 10,
  tagsCookie,
  page,
  location,
}) => {
  const ItemCard = (data, index) => (
    <Bookmark
      data={data}
      title={data?.title}
      subTitle={taxonomyString(data?.seo?.taxonomy)}
      type="article"
      url={data.seo?.pageUrl}
      key={index}
    >
      <Cards
        type="Image"
        bodyType="blog"
        coverImage={data?.featuredImage?.image || data?.featuredImage}
        category={taxonomyString(data?.seo?.taxonomy)}
        title={data?.title}
        description={data.excerpt?.excerpt}
        ctaTitle="Learn more"
        ctaLink={data.seo?.pageUrl}
        key={data.id}
      />
    </Bookmark>
  );

  return (
    <ContentSearchSection
      allContent={allContent}
      hubType={hubType}
      industries={industries}
      topics={topics}
      pageSize={pageSize}
      ItemCard={ItemCard}
      fixedTags={['Commercial']}
      tagsCookie={tagsCookie}
      page={page}
      location={location}
    />
  );
};

export default BlogCardGroupSection;
